import * as React from 'react'
import { Link } from 'gatsby'

import { LayoutWithImage } from '../layouts'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <LayoutWithImage>
    <Seo title="404: Not found" />
    <div className="wrapper">
      <h2>404: Not Found</h2>
      <p>Quelle tristesse cette page n'existe pas</p>

      <Link className="link" to="/" title="Retour à l'accueil">
        Aller à la page d'accueil
      </Link>
    </div>
  </LayoutWithImage>
)

export default NotFoundPage
